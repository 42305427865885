<template>
  <div class="px-3">
    <h3 class="mt-4">
      {{ $getVisibleNames("mesh.taxonomy", true, "Taxonomías") }}
      <AlternativeNameButton
        :table_name="'mesh.taxonomy'"
        :allows_crud="allows_crud"
      ></AlternativeNameButton>
      <!-- Taxonomías -->
    </h3>
    <div class="d-flex justify-content-between mx-2 px-2">
      <div>
        <button
          v-can="'mesh.add_cognitivelevel'"
          v-if="allows_crud && taxonomy && tab_index < 1"
          class="btn btn-secondary btn-sm"
          @click="$bvModal.show(`new-cognitive-level-modal`)"
        >
          Agregar
          {{
            $getVisibleNames("mesh.cognitivelevel", false, "Nivel Cognitivo")
          }}
        </button>
      </div>
      <div class="w-50">
        <b-form-select
          v-model="selected_taxonomy_id"
          :options="taxonomies"
          text-field="name"
          value-field="id"
          size="sm"
        ></b-form-select>
      </div>
      <div>
        <button-add
          v-can="'mesh.add_taxonomy'"
          v-if="allows_crud"
          @click="$bvModal.show('modal-new-taxonomy')"
          :title="`Agregar ${$getVisibleNames(
            'mesh.taxonomy',
            false,
            'Taxonomía'
          )}`"
        ></button-add>
        <button-edit
          v-can="'mesh.change_taxonomy'"
          v-if="allows_crud && taxonomy"
          @click="$bvModal.show('modal-edit-taxonomy')"
          :title="`Editar ${$getVisibleNames(
            'mesh.taxonomy',
            false,
            'Taxonomía'
          )}`"
        ></button-edit>
        <button-delete
          v-can="'mesh.delete_taxonomy'"
          v-if="allows_crud && taxonomy"
          @click="deleteTaxonomy"
          :title="`Eliminar ${$getVisibleNames(
            'mesh.taxonomy',
            false,
            'Taxonomía'
          )}`"
        ></button-delete>
      </div>
    </div>
    <b-tabs class="mt-4" v-model="tab_index">
      <b-tab :title="taxonomy ? taxonomy.name : ''">
        <div
          v-if="taxonomy && taxonomy.active"
          style="text-align: center; font-size: 9pt"
          class="pb-1"
        >
          Ésta es la
          {{ $getVisibleNames("mesh.taxonomy", false, "Taxonomía") }}
          predeterminada para la Institución.
        </div>
        <hr />
        <div class="row">
          <div class="col font-weight-bold">Nivel</div>
          <div class="col font-weight-bold">Descripción</div>
          <div class="col font-weight-bold">Verbos</div>
        </div>
        <div v-if="taxonomy">
          <draggable
            v-model="cognitiveLevelList"
            :disabled="!allows_crud"
            tag="div"
          >
            <CognitiveLevelRow
              v-for="cognitive_level in cognitiveLevelList"
              :key="cognitive_level.id"
              :CognitiveLevel="cognitive_level"
              :taxonomy_id="taxonomy.id"
              @deleted="slotDeleteCognitiveLevel"
              :allows_crud="allows_crud"
            ></CognitiveLevelRow>
          </draggable>
          <div v-if="taxonomy.cognitive_levels.length == 0">
            <p>No tiene Niveles Cognitivos creados.</p>
          </div>
        </div>

        <b-modal
          :id="`modal-new-taxonomy`"
          :hide-footer="true"
          :title="`Crear ${$getVisibleNames(
            'mesh.taxonomy',
            false,
            'Taxonomía'
          )}`"
        >
          <TaxonomyForm @created="slotCreatedTaxonomy"></TaxonomyForm>
        </b-modal>
        <b-modal
          v-if="taxonomy"
          :id="`modal-edit-taxonomy`"
          :hide-footer="true"
          :title="`Editar ${$getVisibleNames(
            'mesh.taxonomy',
            false,
            'Taxonomía'
          )}`"
        >
          <TaxonomyForm
            :Taxonomy="taxonomy"
            @updated="slotUpdatedTaxonomy"
          ></TaxonomyForm>
        </b-modal>
        <b-modal
          v-if="taxonomy"
          :id="`new-cognitive-level-modal`"
          :hide-footer="true"
          title="Crear Nivel Cognitivo"
        >
          <CognitiveLevelForm
            :taxonomy_id="selected_taxonomy_id"
            :order="order"
            @created="slotCreatedCognitiveLevel"
          ></CognitiveLevelForm>
        </b-modal>
      </b-tab>
      <b-tab title="Verbos utilizados sin Nivel Cognitivo">
        <h5 class="my-2 verbs-title">Verbos utilizados sin Nivel Cognitivo</h5>
        <div class="verbs-container">
          <template v-if="orphanedActionsFilter.length > 0">
            <div
              v-for="custom_verb in orphanedActionsFilter"
              :key="custom_verb.id"
            >
              <div v-if="custom_verb.action != null" class="verbs-div">
                {{ custom_verb ? custom_verb.action : "" }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="empty-orphaned-verbs">No hay verbos para mostrar.</div>
          </template>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "TaxonomyView",
  components: {
    CognitiveLevelRow: () =>
      import(
        "@/components/mesh/MethodologicalResources/Taxonomy/CognitiveLevelRow"
      ),
    CognitiveLevelForm: () =>
      import(
        "@/components/mesh/MethodologicalResources/Taxonomy/CognitiveLevelForm"
      ),
    draggable,
    TaxonomyForm: () => import("./TaxonomyForm"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
  },
  data() {
    return {
      selected_taxonomy_id: 1,
      tab_index: 0,
      orphaned_actions: [],
    };
  },
  computed: {
    ...mapGetters({
      taxonomy_active: names.TAXONOMY,
      taxonomies: names.TAXONOMIES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_taxonomy");
      if (has_permission) return has_permission;
      else return false;
    },
    orphanedActionsFilter() {
      let actions_taxonomy = [];
      if (this.taxonomy_active) {
        this.taxonomy.cognitive_levels.forEach((cognitive_level) => {
          actions_taxonomy = actions_taxonomy.concat(
            cognitive_level.verbs.map((x) => x.name.toLowerCase())
          );
        });
      }
      actions_taxonomy = [...new Set(actions_taxonomy)];
      return this.orphaned_actions
        .filter(
          (verb) =>
            actions_taxonomy.filter((action_taxonomy) =>
              this.$equals(action_taxonomy, verb.action)
            ).length == 0
        )
        .sort((a, b) => a.action.localeCompare(b.action));
    },
    taxonomy() {
      return this.taxonomies.find((x) => x.id == this.selected_taxonomy_id);
    },
    order() {
      let order = 0;
      if (this.taxonomy) {
        this.taxonomy.cognitive_levels.forEach((element) => {
          if (element.level > order) order = element.level;
        });
      }
      return order + 1;
    },
    cognitiveLevelList: {
      get() {
        if (!this.taxonomy) return [];
        let list = this.taxonomy.cognitive_levels;
        list.sort(function (a, b) {
          if (a.level < b.level) return -1;
          if (a.level > b.level) return 1;
          return 0;
        });
        return list;
      },
      set(list) {
        this.taxonomy.cognitive_levels = list;
        let order = 1;
        list.forEach((element) => {
          element.level = order;
          this.patchCognitiveLevel(element.id, { level: order });
          order++;
        });
      },
    },
  },
  methods: {
    fetchOrphanedVerbs() {
      this.$restful.Get(`/common/orphaned-actions/`).then((response) => {
        this.orphaned_actions = response.actions_without_verb;
      });
    },
    patchCognitiveLevel(id, item) {
      this.$restful.Patch(`/mesh/cognitive-level/${id}/`, item);
    },
    slotDeleteCognitiveLevel(taxonomy_id) {
      let index = this.taxonomy.cognitive_levels.findIndex(
        (x) => x.id == taxonomy_id
      );
      if (index != -1) this.taxonomy.cognitive_levels.splice(index, 1);
    },
    slotCreatedTaxonomy(taxonomy) {
      this.$bvModal.hide("modal-new-taxonomy");
      this.$store.dispatch(names.FETCH_TAXONOMY, taxonomy.id).then(() => {
        this.selected_taxonomy_id = taxonomy.id;
      });
    },
    slotUpdatedTaxonomy(taxonomy) {
      this.$bvModal.hide("modal-edit-taxonomy");
      this.selected_taxonomy_id = taxonomy.id;
    },
    deleteTaxonomy() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.taxonomy",
          false,
          "Taxonomía"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_TAXONOMY, this.taxonomy.id)
            .then(() => {
              if (this.taxonomies.length > 0) {
                this.selected_taxonomy_id = this.taxonomies[0].id;
                this.taxonomies.forEach((x) => {
                  if (x.active) this.selected_taxonomy_id = x.id;
                });
              }
            });
        }
      });
    },
    slotCreatedCognitiveLevel(cognitive_level) {
      this.taxonomy.cognitive_levels.push(cognitive_level);
      this.$bvModal.hide(`new-cognitive-level-modal`);
    },
  },
  watch: {
    taxonomies: function () {
      let taxonomy = this.taxonomies.find((x) => x.active);
      if (taxonomy) this.selected_taxonomy_id = taxonomy.id;
    },
  },
  created() {
    this.fetchOrphanedVerbs();
    if (this.taxonomies.length > 0)
      this.selected_taxonomy_id = this.taxonomies[0].id;
    this.$store.dispatch(names.FETCH_TAXONOMIES).then((response) => {
      response.forEach((x) => {
        if (x.active) this.selected_taxonomy_id = x.id;
      });
    });
  },
};
</script>

<style scoped>
button {
  margin: 1px 5px 1px 5px;
}
.verbs-title {
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.1em !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.verbs-container {
  width: 100%;
  min-height: fit-content;
  background: var(--secondary-color);
  margin: 0 !important;
  padding: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.verbs-div {
  background-color: var(--primary-color);
  color: var(--secondary-font-color);
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.15em;
  padding: 0.4em 0.8em;
  flex-grow: 1;
  font-size: var(--primary-font-size);
  text-align: center;
  font-weight: bold;
}
.empty-orphaned-verbs {
  color: var(--secondary-font-color);
}
</style>